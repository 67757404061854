import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { GenericPage } from "../../components/mdx/page";
import { Accordion } from "../../components/widgets/accordion";
import { HelpPopup } from "../../components/widgets/help-popup";
import { ContentPlusDecor } from "../../components/widgets/content-plus-decor";
import { FaQuestionCircle } from "react-icons/fa";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = GenericPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1>{`Help & Support`}</h1>
    <ContentPlusDecor decor={<FaQuestionCircle mdxType="FaQuestionCircle" />} mdxType="ContentPlusDecor">
      <Accordion title="Frequently Asked Questions" mdxType="Accordion">
        <h3>{`General Questions`}</h3>
        <ul>
          <li><HelpPopup title="What's your service?" mdxType="HelpPopup">
              <p><em parentName="p">{`TuringTrader.com`}</em>{` offers tactical portfolios for DIY investors. These portfolios are actively managed and rotate their assets based on observed market conditions. As a member, you get:`}</p>
              <ul>
                <li parentName="ul">{`the exact asset allocation to replicate the portfolios`}</li>
                <li parentName="ul">{`simulations of the hypothetical portfolio performance`}</li>
                <li parentName="ul">{`background information explaining the mechanics of our portfolios`}</li>
                <li parentName="ul">{`notifications when it is time to rotate the assets`}</li>
              </ul>
            </HelpPopup></li>
          <li><HelpPopup title="Where is my money?" mdxType="HelpPopup">
              <p><em parentName="p">{`TuringTrader.com`}</em>{` is a DIY service, and we never take custody of your assets. Instead, your cash and assets are held in a brokerage account of your choosing. If you already have a brokerage account, the chances are high that you can continue using it.`}</p>
            </HelpPopup></li>
          <li><HelpPopup title="How much money do I need to invest?" mdxType="HelpPopup">
              <p><em parentName="p">{`TuringTrader.com`}</em>{` offers a wide range of portfolios. Some of our portfolios only invest in one or two ETFs at a time and can be implemented with just $5,000. Other portfolios are more complex and hold individual stocks, requiring a minimum investment of $100,000. We discuss the minimum account size for each portfolio in its section about `}<em parentName="p">{`Account and Tax Considerations`}</em>{`.`}</p>
            </HelpPopup></li>
          <li><HelpPopup title="What about taxes?" mdxType="HelpPopup">
              <p>{`Tactical asset allocation implies that you are rotating your assets. Whenever you do so, you realize profits and losses, resulting in taxation. Therefore, our portfolios work best in tax-deferred accounts, e.g., a typical retirement account. There are two avenues to use tactical asset allocation in a taxable account:`}</p>
              <ul>
                <li parentName="ul"><strong parentName="li">{`Tax-efficient portfolios`}</strong>{`. These portfolios either trade less often or use advanced hedging techniques to avoid selling assets. An example is our `}<a parentName="li" {...{
                    "href": "/portfolios/tt-all-stars-tax/"
                  }}>{`All-Stars Tax-Efficient`}</a>{` portfolio.`}</li>
                <li parentName="ul"><strong parentName="li">{`High-growth portfolios`}</strong>{`. These portfolios offer outsized returns that go far beyond the performance of buy-and-hold. While investing in these portfolios will result in a high tax burden, their returns are well worth it. An example is our `}<a parentName="li" {...{
                    "href": "/portfolios/tt-all-stars-stocks/"
                  }}>{`All-Stars Stocks`}</a>{` portfolio.`}</li>
              </ul>
            </HelpPopup></li>
          <li><HelpPopup title="Can you give me advice?" mdxType="HelpPopup">
              <p>{`We are happy to help with any problems you might face while operating our platform, and we can help you understand how our portfolios work. However, please know that we cannot provide any advice that is personalized to your financial situation.`}</p>
            </HelpPopup></li>
          <li><HelpPopup title="Can you trade for me?" mdxType="HelpPopup">
              <p>{`Almost. `}<em parentName="p">{`TuringTrader.com`}</em>{` never takes custody of your assets and cannot trade for you. However, we offer managed accounts through our partnership with `}<em parentName="p">{`Bertram Solutions LLC`}</em>{`, a registered investment advisor specializing in algorithmic trading. Read more about managed accounts `}<a parentName="p" {...{
                  "href": "/help/managed-accounts/"
                }}>{`here`}</a>{`.`}</p>
            </HelpPopup></li>
        </ul>
        <h3>{`Membership`}</h3>
        <ul>
          <li><HelpPopup title="Which plan do I need?" mdxType="HelpPopup">
              <p>{`We offer the following plans:`}</p>
              <ul>
                <li parentName="ul"><strong parentName="li">{`Basic`}</strong>{`. This plan is always free and includes full access to our Basic portfolios. Most of these Basic portfolios come from books and publications. These portfolios are a great starting point for tactical asset allocation.`}</li>
                <li parentName="ul"><strong parentName="li">{`Premium`}</strong>{`. This paid plan includes full access to our Premium portfolios. Our Premium portfolios are based on our original research, and these portfolios typically provide higher returns and lower risk than our Basic portfolios.`}</li>
                <li parentName="ul"><strong parentName="li">{`Infinite`}</strong>{`. This is our high-end plan and includes access to more advanced features, most notably our API to support your own scripting and automation.`}</li>
              </ul>
              <p>{`Check our comparison of plans `}<a parentName="p" {...{
                  "href": "/sign-up/"
                }}>{`here`}</a>{`.`}</p>
            </HelpPopup></li>
          <li><HelpPopup title="How long is my commitment?" mdxType="HelpPopup">
              <p>{`We offer monthly and annual plans. You can cancel your membership at any time, effective at the end of the current billing cycle.`}</p>
            </HelpPopup></li>
          <li><HelpPopup title="How do I change my credit card?" mdxType="HelpPopup">
              <p>{`Click on `}<em parentName="p">{`Your Account`}</em>{`, and in the billing section, you find a link where you can update your credit card information.`}</p>
            </HelpPopup></li>
          <li><HelpPopup title="Can I change my membership plan?" mdxType="HelpPopup">
              <p>{`Of course! Click on `}<em parentName="p">{`Your Account`}</em>{`, and in the `}<em parentName="p">{`Plans`}</em>{` section, you can upgrade or downgrade your membership. You can find an overview of our membership plans `}<a parentName="p" {...{
                  "href": "/sign-up"
                }}>{`here`}</a>{`.`}</p>
            </HelpPopup></li>
          <li><HelpPopup title="How do I cancel my subscription and revert to a free account?" mdxType="HelpPopup">
              <p>{`Click on `}<em parentName="p">{`Your Account`}</em>{`, and you can view and cancel your subscription in the billing section. Canceling a premium membership will automatically revert to a free `}<em parentName="p">{`Basic`}</em>{` membership. `}</p>
            </HelpPopup></li>
          <li><HelpPopup title="Can I use your portfolios for my advisor clients?" mdxType="HelpPopup">
              <p>{`Our `}<a parentName="p" {...{
                  "href": "/company/terms-of-use/"
                }}>{`Terms of Use`}</a>{` limit the use of `}<em parentName="p">{`TuringTrader`}</em>{` portfolios to personal and non-commercial use. The use of our portfolios for the benefit or on behalf of others requires a separate and individually negotiated agreement. Read more `}<a parentName="p" {...{
                  "href": "/help/professional-advisors/"
                }}>{`here`}</a>{`.`}</p>
            </HelpPopup></li>
        </ul>
        <h3>{`Portfolios`}</h3>
        <ul>
          <li><HelpPopup title="How do I choose a portfolio?" mdxType="HelpPopup">
              <p>{`When choosing a portfolio, you should focus on your risk tolerance first. Once you decided on a risk level, you should look for the following:`}</p>
              <ul>
                <li parentName="ul"><strong parentName="li">{`Conviction`}</strong>{`. Do you understand how the portfolio works, and do you agree with this approach to investing? It is essential you do because otherwise, it will become hard to track the portfolio in volatile or bearish markets without second-guessing.`}</li>
                <li parentName="ul"><strong parentName="li">{`Financial Situation`}</strong>{`. Does your account size match the portfolio's minimum requirements? How about any significant withdrawal needs? Can you trade the portfolio's assets in your account? Did you consider the tax implications?`}</li>
                <li parentName="ul"><strong parentName="li">{`Lifestyle`}</strong>{`. Does the portfolio match your lifestyle? Can you meet the portfolio's requirements in terms of frequency and complexity of rebalancing?`}</li>
              </ul>
              <p>{`For more details, check our article `}<a parentName="p" {...{
                  "href": "/help/find-your-portfolio/"
                }}>{`here`}</a>{`.`}</p>
            </HelpPopup></li>
          <li><HelpPopup title="Which risk level is right for me?" mdxType="HelpPopup">
              <p>{`Many investors overestimate their appetite for risk until they face drawdowns. Drawdowns are always painful and might result in you second-guessing the portfolio or abandoning it altogether. Both are clear indications that the risk level is too high. If in doubt, choose a portfolio with lower volatility and lower drawdowns.`}</p>
              <p>{`While accumulating wealth, portfolio volatility is less critical because the time horizon to retirement is longer. However, if you are nearing retirement or already decumulating wealth, you should definitely pick a portfolio with low volatility.`}</p>
            </HelpPopup></li>
          <li><HelpPopup title="Do you beat the market?" mdxType="HelpPopup">
              <p>{`Investors can beat the market in many different ways:`}</p>
              <ul>
                <li parentName="ul"><strong parentName="li">{`Risk-adjusted returns`}</strong>{`. Portfolios in this category offer higher returns per unit of risk. Depending on the risk level, these portfolios might be trailing their benchmarks in absolute terms.`}</li>
                <li parentName="ul"><strong parentName="li">{`Make more by losing less`}</strong>{`. Portfolios in this category create value by losing less during market downturns. Even though they trail their benchmark in most years, they still come out ahead in the long term.`}</li>
                <li parentName="ul"><strong parentName="li">{`Beat the market most years`}</strong>{`. Portfolios in this category beat their benchmark in most years, even outside of recessions. These portfolios are most likely quite aggressive.`}</li>
              </ul>
              <p><em parentName="p">{`TuringTrader.com`}</em>{` offers portfolios in all three categories. It is crucial to keep in mind that returns are meaningless unless put into context with risk.`}</p>
            </HelpPopup></li>
          <li><HelpPopup title="How about crypto-currencies?" mdxType="HelpPopup">
              <p>{`We are undoubtedly curious about crypto-currencies and are closely watching where things are going. However, so far, we do not offer any portfolios that include cryptos. Here is what we are waiting for:`}</p>
              <ul>
                <li parentName="ul"><strong parentName="li">{`Regulation of trading venues`}</strong>{`. Right now, the crypto markets seem highly intransparent. We are concerned about missing regulation and the possibility of pump & dump schemes.`}</li>
                <li parentName="ul"><strong parentName="li">{`Insufficient data`}</strong>{`. So far, it seems that we might not have seen enough data to understand the investment cycles of crypto-currencies. It might well be that all we have seen is a single bull run. Without a better understanding of that, we cannot develop strategies that survive the test of time.`}</li>
                <li parentName="ul"><strong parentName="li">{`Availability of assets`}</strong>{`. At some point, we want to include cryptos in our portfolios as an additional asset class, and traded at the same venues as stocks and ETFs. We are waiting for crypto ETFs to be available in the USA.`}</li>
              </ul>
            </HelpPopup></li>
          <li><HelpPopup title="What are those charts and metrics?" mdxType="HelpPopup">
              <p>{`We aim to provide you the best possible insight into the historical performance of our portfolios. And while historical performance is not always indicative of future behavior, the portfolio's behavior during past bull and bear markets still gives valuable guidance. Most of the charts and metrics we use are industry standard, but we also use some proprietary charts. We explain all of our charts and metrics `}<a parentName="p" {...{
                  "href": "/help/charts-metrics/"
                }}>{`here`}</a>{`.`}</p>
            </HelpPopup></li>
        </ul>
        <h3>{`Rebalancing`}</h3>
        <ul>
          <li><HelpPopup title="How much work is this?" mdxType="HelpPopup">
              <p>{`The effort required for portfolio maintenance depends on the portfolio. When choosing a portfolio, consider the frequency and complexity of rebalancing. Regarding the frequency, we offer portfolios on monthly, weekly, or daily schedules. The complexity ranges from just two assets to ten or more assets. It is vital to pick a portfolio that matches your lifestyle. If you cannot commit to the portfolio's required rebalancing schedule, you should most likely choose a different portfolio.`}</p>
              <p>{`As a rule of thumb, you can assume that you might need five minutes to open your browser and trading platforms and no more than two minutes per asset. For most portfolios, rebalancing should take 15 minutes or less. It is also worth noting that even portfolios rebalancing on a daily schedule will most likely have many days with little or no change.`}</p>
              <p>{`Read `}<a parentName="p" {...{
                  "href": "/help/rebalance/"
                }}>{`this article`}</a>{` for a more detailed description of the rebalancing routine.`}</p>
            </HelpPopup></li>
          <li><HelpPopup title="When do I rebalance?" mdxType="HelpPopup">
              <p>{`To track our portfolios, you need to enter your orders while the markets are closed. These orders will then be filled on the next day's open. There is no rush and no need to sit in front of your computer at a specific time.`}</p>
              <p>{`The markets close at 4:00 pm Eastern Time (New York), and we typically start recalculating our portfolios 4.5 hours after that. You then have until 9:30 am Eastern Time to enter your orders. Learn more about the rebalancing process `}<a parentName="p" {...{
                  "href": "/help/account-rebalancing/"
                }}>{`here`}</a>{`.`}</p>
            </HelpPopup></li>
          <li><HelpPopup title="How do I rebalance?" mdxType="HelpPopup">
              <p>{`Rebalancing is easy to do. In a nutshell, you need to perform the following steps:`}</p>
              <p>{`Determine the target allocation. If you are on a Premium plan, our position size calculator does this for you.
Calculate the orders. Simply put, you need to calculate the difference between the target allocation and the current holdings in your account.
Submit your orders. In this step, you enter the orders into your broker's website or software.
Learn more about the rebalancing steps `}<a parentName="p" {...{
                  "href": "/help/account-rebalancing/"
                }}>{`here`}</a>{`.`}</p>
            </HelpPopup></li>
          <li><HelpPopup title="Do you send notifications?" mdxType="HelpPopup">
              <p>{`Yes, we do. When it is time to rebalance your portfolio, we send out email notifications. Learn more about how to set this up `}<a parentName="p" {...{
                  "href": "/help/investment-dashboard/"
                }}>{`here`}</a>{`.`}</p>
            </HelpPopup></li>
        </ul>
        <h3>{`Simulations`}</h3>
        <ul>
          <li><HelpPopup title="How accurate are your backtests?" mdxType="HelpPopup">
              <p>{`We aim to simulate our portfolios under the most realistic conditions. We assume market fills at the opening price, and we include commissions in every transaction. However, we do not account for slippage. In our experience from trading for our own accounts, our backtests closely match actual trading.`}</p>
              <p>{`For a detailed description of our backtest methodology, see `}<a parentName="p" {...{
                  "href": "/articles/backtesting/"
                }}>{`here`}</a>{`.`}</p>
            </HelpPopup></li>
          <li><HelpPopup title="Why do your charts start in 2007?" mdxType="HelpPopup">
              <p>{`Our simulations shall provide you with an impression of the portfolio's performance under typical market conditions. To do so, we want the longest simulation range possible, but at least one complete market cycle, including growth, peak, decline, and trough. Unfortunately, many assets, primarily ETFs, have not been available that long, which creates some hurdles.`}</p>
              <p>{`We settled for early 2007, which is right before the 2008 recession. This start date strikes a good balance between a long simulation range and limited use of proxies to substitute assets before their inception date.`}</p>
            </HelpPopup></li>
        </ul>
        <h3>{`Learning`}</h3>
        <ul>
          <li><HelpPopup title="Where can I learn more about tactical asset allocation?" mdxType="HelpPopup">
              <p>{`Learning about trading strategies and quantitative finance is incredibly rewarding. It's fun, and it feels good to take control of your finances. If you want to dive deeper, we suggest approaching this from three angles:`}</p>
              <ul>
                <li parentName="ul"><strong parentName="li">{`Read`}</strong>{`. There are many excellent publications out there. While they typically do not publish well-rounded investment strategies in all their detail, they provide valuable starting points and food for thought. Check out our Basic portfolios, which we have taken verbatim from books and publications, for some links.`}</li>
                <li parentName="ul"><strong parentName="li">{`Simulate`}</strong>{`. Once you found a strategy that looks interesting enough, start experimenting with it and run your own simulations. What happens over longer ranges? What happens if you swap some assets? Experimenting with a strategy provides tremendous insight into what works and what not to do. If you are looking for a powerful open-source simulator, have a look at our open-source project at `}<a parentName="li" {...{
                    "href": "http://www.turingtrader.org"
                  }}>{`www.turingtrader.org`}</a>{`.`}</li>
                <li parentName="ul"><strong parentName="li">{`Trade`}</strong>{`. And finally, trade the strategies with real money. Never underestimate the psychological implications of trading your hard-earned money. It will help you shape your view on risk-taking and set the direction for future strategy development.`}</li>
              </ul>
            </HelpPopup></li>
        </ul>
        <h3>{`More`}</h3>
        <ul>
          <li><HelpPopup title="My question is not answered" mdxType="HelpPopup">
              <p>{`This FAQ sure can't answer every question. However, we have written many detailed articles describing our workflow, processes, and reasearch in detail. By consulting these articles, you will soon gain a good understanding of how our portfolios work, how we approach investing, and what our methodologies are.`}</p>
              <p>{`We are here to help; feel free to `}<a parentName="p" {...{
                  "href": "/company/contact/"
                }}>{`reach out`}</a>{`.`}</p>
            </HelpPopup></li>
        </ul>
      </Accordion>
      <Accordion title="How to Use Our Site" mdxType="Accordion">
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/help/what-to-expect/"
            }}>{`What to Expect`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/help/getting-started/"
            }}>{`Getting Started With TuringTrader`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/help/tactical-asset-allocation/"
            }}>{`Tactical Asset Allocation`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/help/find-your-portfolio/"
            }}>{`Finding Your Portfolio`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/help/investment-dashboard/"
            }}>{`Configure Your Dashboard`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/help/account-rebalancing/"
            }}>{`Account Rebalancing`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/help/charts-metrics/"
            }}>{`Understanding Charts and Metrics`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/help/account-groups/"
            }}>{`Account Grouping and Consolidation`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/help/brokerage-account/"
            }}>{`Brokerage Accounts`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/help/investors-outside-us/"
            }}>{`Investors Outside of the U.S.`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/help/api/"
            }}>{`API Access`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/help/managed-accounts/"
            }}>{`Managed Accounts`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/help/habits-for-success/"
            }}>{`Habits for Success`}</a></li>
        </ul>
      </Accordion>
      <Accordion title="Status & Troubleshooting" mdxType="Accordion">
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/help/status/"
            }}>{`Site Status`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/help/member-info/"
            }}>{`Membership Info`}</a></li>
        </ul>
      </Accordion>
    </ContentPlusDecor>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      